import * as React from "react";
import CoverPage from "src/components/cover-page";
import SiteFooter from "src/components/layout/footer";
import {Link} from 'gatsby';


export default function IndexPage() {
    return <div>
        <CoverPage />
        <Menu />
        <SiteFooter style={{color: '#aaa', background: '#222'}} />
    </div>;
}


function Menu() {
    const linkStyle = {
        color: '#fff',
        letterSpacing: '.2em',
        fontSize: '1.6em',
        textDecoration: 'none',
    };

    const menuItems = [
        {label: '[GAMES]', to: '/games'},
        {label: '[GAMES]', to: '/games'},
        {label: '[GAMES]', to: '/games'},
    ];

    return <div style={{background: '#000', color: '#fff'}}
                className="p-5 text-center d-md-flex flex-row justify-content-around">

        {menuItems.map(({label, to}, idx)=>
            <Link key={idx} to={to} style={linkStyle}>
                {label}
            </Link>
        )}

    </div>;
}
