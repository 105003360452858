import * as React from "react";


export default function SiteFooter(props) {
    const KD = <a href="https://ksdunne.com">Katie Dunne</a>;
    const SS = <a href="https://samuelesanti.com">Sam Santi</a>;

    return <footer className="p-3 text-center" {...props}>
          &copy; {(new Date()).getFullYear()} {KD} &amp; {SS} ❤
    </footer>;
}
