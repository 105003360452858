import * as React from "react";
import styles from './index.module.scss';


export default function CoverPage() {
    console.log(styles);
    return <div className={styles.coverPage}>
        <CoverPageLayer variant="white_on_purple" />
        <CoverPageLayer variant="black_on_yellow" />
    </div>;
}


function CoverPageLayer({variant}) {
    const className = `${styles.coverPageLayer} ${styles['coverPageLayer__' + variant]}`;
    return <div className={className}>
        <div className={styles.title}>
            KDSS
        </div>
        <div className={styles.subtitle}>
            GAMES
        </div>
    </div>;
}
